


































import { Component, Vue, Prop } from 'vue-property-decorator';
import { SystemCourse } from '@/types/course.type';

@Component
export default class SystemCourseDetailsComponent extends Vue {
    @Prop(Object) readonly data!: SystemCourse;
}
